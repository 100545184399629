// Components
const BaseRouterView = () => import('@/features/onboarding/router_views/BaseRouterView')
const Onboarding = () => import('@/features/onboarding/views/Onboarding.vue')

export default [
  {
    path: '/onboarding/',
    component: BaseRouterView,
    children: [
      {
        path: '',
        name: 'Onboarding',
        meta: {
          requiresAuth: true
        },
        component: Onboarding
      }
    ]
  }
]
