<template>
  <v-container
    v-if="subscriptionLimitReached && showAlert && $route.params.workspaceId"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title class="error--text">
            <v-avatar
              size="34"
              class="mr-3"
            >
              <v-icon color="error">
                {{ $vuetify.icons.values.error }}
              </v-icon>
            </v-avatar>
            {{ $t('subscriptions.limitReachedTitle') }}
          </v-card-title>

          <v-card-text>
            {{ $t('subscriptions.limitReachedBody') }}
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              rounded
              disabled
            >
              {{ $t('subscriptions.upgrade') }}
            </v-btn>
            <v-btn
              text
              rounded
              color="primary"
              @click="showAlert = false"
            >
              {{ $t('general.dismiss') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SubscriptionAlert',
  data () {
    return {
      showAlert: true
    }
  },
  computed: {
    ...mapGetters([
      'subscriptionLimitReached'
    ])
  },
  watch: {
    '$route.params.workspaceId' (workspaceId) {
      if (workspaceId) {
        this.showAlert = true
      }
    }
  }
}
</script>
