<template>
  <v-data-iterator
    v-if="displayCondition"
    :items="items"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:header>
      <v-subheader class="text-uppercase">
        {{ title }}
      </v-subheader>
    </template>
    <template v-slot:item="props">
      <component
        :is="props.item.component"
        :item="props.item"
      />
    </template>
  </v-data-iterator>
</template>

<script>
import NavigationDrawerGroup from '@/components/navigation/drawer/Group'
import NavigationDrawerItem from '@/components/navigation/drawer/Item'

export default {
  name: 'NavigationItemIterator',
  components: {
    NavigationDrawerGroup,
    NavigationDrawerItem
  },
  props: {
    displayCondition: { type: Boolean, default: false },
    title: { type: String, default: '' },
    items: { type: Array, default: () => { return [] } }
  }
}
</script>
