import api from '@/api/modules/workspaces'
import * as types from '@/store/mutation-types'

const state = {
  loading: false,
  list: [],
  activeWorkspace: {}
}

const getters = {
  activeWorkspace: state => state.activeWorkspace,
  activeWorkspaceDataIsReady: state => Boolean(state.activeWorkspace.name)
}

const actions = {
  getUserWorkspaces ({ commit }) {
    commit(types.workspaces.loading, true)
    return api.getUserWorkspaces()
      .then((workspacesList) => {
        commit(types.workspaces.list, workspacesList)
        commit(types.workspaces.loading, false)
      })
      .catch((error) => {
        commit(types.api.error, error)
        commit(types.workspaces.loading, false)
      })
  },
  getActiveWorkspace ({ commit }) {
    return api.getActiveWorkspace()
      .then((activeWorkspace) => {
        commit(types.workspaces.activeWorkspace, activeWorkspace)
      })
      .catch((error) => {
        commit(types.api.error, error)
      })
  },
  clearUserWorkspaces ({ commit }) {
    commit(types.workspaces.clearList)
  },
  clearActiveWorkspace ({ commit }) {
    commit(types.workspaces.clearActiveWorkspace)
  }
}

const mutations = {
  [types.workspaces.loading] (state, value) {
    state.loading = value
  },
  [types.workspaces.list] (state, workspacesList) {
    state.list = workspacesList
  },
  [types.workspaces.clearList] (state) {
    state.list = []
  },
  [types.workspaces.activeWorkspace] (state, activeWorkspace) {
    state.activeWorkspace = activeWorkspace
  },
  [types.workspaces.clearActiveWorkspace] (state) {
    state.activeWorkspace = {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
