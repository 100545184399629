<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    :nudge-width="250"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        class="ml-2"
        v-on="on"
      >
        <v-icon>
          {{ $vuetify.icons.values.moreVertical }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar tile>
            <v-icon :color="iconColor">
              {{ icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-subheader>
          {{ $t('general.actions') }}
        </v-subheader>
        <v-list-item
          ripple
          :disabled="!hasWorkspacePermission(permissions.edit)"
          @click="$bus.emit('open:edit-dialog')"
        >
          <v-list-item-icon>
            <v-icon
              :disabled="!hasWorkspacePermission(permissions.edit)"
            >
              {{ $vuetify.icons.values.edit }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('general.edit') }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-subheader>
          {{ $t('general.danger') }}
        </v-subheader>
        <v-list-item
          ripple
          :disabled="!hasWorkspacePermission(permissions.delete)"
          @click="$bus.emit('open:delete-dialog')"
        >
          <v-list-item-icon>
            <v-icon
              :disabled="!hasWorkspacePermission(permissions.delete)"
              color="error"
            >
              {{ $vuetify.icons.values.deleteFile }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DetailsToolsMenu',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    permissions: { type: Object, default: () => { return {} } }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission'
    ]),
    iconColor () {
      return this.$route.meta.iconColor
    }
  }
}
</script>
