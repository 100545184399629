import router from '@/router'

export function getWorkspaceId () {
  return router.currentRoute.params.workspaceId
}

export function getClientId () {
  return router.currentRoute.params.clientId
}

export function getRouteDetailId () {
  return router.currentRoute.params.routeDetailId.toString()
}

export function getViewContext () {
  return router.currentRoute.meta.viewContext.toString()
}

export function getFlowContext () {
  return router.currentRoute.meta.flowContext.toString()
}

export function getAppContext () {
  return router.currentRoute.matched.find(route => route.meta.appContext).meta.appContext
}
