import store from '@/store'
import router from '@/router'
import { getWorkspaceId, getClientId } from '@/api/utils/route_params'

const handle400Response = error => {
  store.dispatch('setFormError', true)
  store.dispatch('setFormErrorMessages', error.response.data)
}

const handle401Response = error => {
  store.dispatch('setApiError', error.response)
  router.push({ name: 'Unauthorized' })
}

const handle403Response = () => {
  if (getWorkspaceId()) {
    router.push({ name: 'WorkspacesNoPermission' })
  }
  if (getClientId()) {
    router.push({ name: 'ClientsNoPermission' })
  }
}

const handle404Response = () => {
  router.push({ name: 'PageNotFound' })
}

const errorStatusMap = {
  400: error => handle400Response(error),
  401: error => handle401Response(error),
  403: () => handle403Response(),
  404: error => handle404Response(error)
}

export const responseError = error => {
  errorStatusMap[error.response.status]
    ? errorStatusMap[error.response.status](error)
    : store.dispatch('setApiError', error.response)
}

export const requestError = error => {
  store.dispatch('setApiError', error)
  store.dispatch('setNetworkError')
}
