import { apiHttpService } from '@/api'
import { getClientId } from '@/api/utils/route_params'

export default {
  async getClientPermissions () {
    try {
      const response = await apiHttpService({
        url: '/clients/' + getClientId() + '/permissions/',
        method: 'GET'
      })
      return Promise.resolve(response.data.permissions)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
