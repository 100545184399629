const ReportSchedulesList = () => import('@/features/schedules/views/List.vue')
const ReportSchedulesDetails = () => import('@/features/schedules/views/Details.vue')

export default [
  {
    path: '/workspaces/:workspaceId/report-schedules/',
    name: 'ReportSchedulesList',
    meta: {
      requiresAuth: true,
      title: 'schedules.header.title',
      icon: 'schedule',
      filtered: true,
      viewContext: 'schedules'
    },
    component: ReportSchedulesList
  },
  {
    path: '/workspaces/:workspaceId/report-schedules/:routeDetailId',
    name: 'ReportSchedulesDetails',
    meta: {
      requiresAuth: true,
      title: 'schedules.detail.header.title',
      icon: 'schedule',
      filtered: false,
      viewContext: 'schedules'
    },
    component: ReportSchedulesDetails
  }
]
