const DocumentReportsList = () => import('@/features/reports/views/List.vue')
const DocumentReportsDetails = () => import('@/features/reports/views/Details.vue')

export default [
  {
    path: '/workspaces/:workspaceId/document-reports/',
    name: 'DocumentReportsList',
    meta: {
      requiresAuth: true,
      title: 'reports.header.title',
      icon: 'report',
      filtered: true,
      viewContext: 'reports'
    },
    component: DocumentReportsList
  },
  {
    path: '/workspaces/:workspaceId/document-reports/:routeDetailId/',
    name: 'DocumentReportsDetails',
    meta: {
      requiresAuth: true,
      title: 'reports.detail.header.title',
      icon: 'report',
      filtered: false
    },
    component: DocumentReportsDetails
  }
]
