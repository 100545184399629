<template>
  <v-snackbar
    v-model="apiSuccess"
    :timeout="4000"
    rounded
    color="success"
    data-cy="api-success-message"
  >
    {{ $t('api.success') }}
    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        text
        rounded
        v-bind="attrs"
        @click.native="clearApiSuccess()"
      >
        {{ $t('general.filters.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ApiSuccessMessage',
  computed: {
    ...mapGetters([
      'getApiSuccess'
    ]),
    apiSuccess: {
      get () {
        return this.getApiSuccess
      },
      set () {
        this.clearApiSuccess()
      }
    }
  },
  methods: {
    ...mapActions([
      'clearApiSuccess'
    ])
  }
}
</script>
