<template>
  <v-list-group
    :prepend-icon="$vuetify.icons.values[item.icon]"
    :disabled="item.disabled"
    :group="item.group"
    active-class="blue--text text--darken-4"
    no-action
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ item.activator }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="subItem in item.children"
      :key="subItem.text"
      active-class="blue--text text--darken-4"
      :to="subItem.to"
      :exact="item.exact"
      :data-cy="subItem.to.name"
    >
      <v-list-item-content>
        <v-list-item-title>{{ subItem.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: 'NavigationDrawerGroup',
  props: {
    item: { type: Object, default: () => { return {} } }
  }
}
</script>
