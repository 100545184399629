import api from '@/api/modules/workspaces/pipelines'
import * as types from '@/store/mutation-types'

const state = {
  activeWorkspacePipelines: [],
  loadingPipelines: false
}

const getters = {
  activeWorkspacePipelines: state => state.activeWorkspacePipelines,
  getPipelinesLoadingStatus: state => state.loadingPipelines
}

const actions = {
  getActiveWorkspacePipelines ({ commit }) {
    commit(types.workspaces.pipelinesLoading, true)
    return api.getActiveWorkspacePipelines()
      .then((pipelinesList) => {
        commit(types.workspaces.pipelinesLoading, false)
        commit(types.workspaces.pipelines, pipelinesList)
      })
      .catch((error) => {
        commit(types.workspaces.pipelinesLoading, false)
        commit(types.api.error, error)
      })
  }
}

const mutations = {
  [types.workspaces.pipelines] (state, pipelinesList) {
    state.activeWorkspacePipelines = pipelinesList
  },
  [types.workspaces.pipelinesLoading] (state, status) {
    state.loadingPipelines = status
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
