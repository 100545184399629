import api from '@/api/modules/subscriptions'
import * as types from '@/store/mutation-types'

const state = {
  subscription: {},
  hasSubscription: false,
  loadingSubscription: false
}

const getters = {
  subscriptionLimitReached: state => {
    return state.subscription.documentsUsage >= state.subscription.maxDocuments
  },
  getDocumentUsagePercentage: state => {
    return state.subscription.documentsUsage / state.subscription.maxDocuments * 100
  }
}

const actions = {
  async getSubscriptionData ({ commit }) {
    try {
      commit(types.subscription.loading, true)
      const subscriptionData = await api.getSubscription()
      commit(types.subscription.set, subscriptionData)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit(types.subscription.clear)
      }
    } finally {
      commit(types.subscription.loading, false)
    }
  },
  clearSubscriptionData ({ commit }) {
    commit(types.subscription.clear)
  }
}

const mutations = {
  [types.subscription.set] (state, subscriptionData) {
    state.subscription = { ...subscriptionData }
    state.hasSubscription = true
  },
  [types.subscription.clear] (state) {
    state.subscription = {}
    state.hasSubscription = false
  },
  [types.subscription.loading] (state, value) {
    state.loadingSubscription = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
