import home from '@/features/home/routes'
import workspaces from '@/features/workspaces/routes'
import administration from '@/features/administration/routes'
import onboarding from '@/features/onboarding/routes'
import errors from '@/features/errors/routes'

export const routes = [
  {
    path: '*',
    redirect: '/'
  },
  ...home,
  ...workspaces,
  ...administration,
  ...onboarding,
  ...errors
]
