import { mapGetters } from 'vuex'

export const drawer = {
  computed: {
    ...mapGetters([
      'hasWorkspacePermission',
      'hasClientPermission',
      'subscriptionLimitReached',
      'activeWorkspace'
    ]),
    workspaceContext () {
      return !!this.$route.params.workspaceId
    },
    administrationContext () {
      return !!this.$route.params.clientId
    },
    workspaceNavigation () {
      return [
        {
          disabled: false,
          icon: 'dashboard',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.workspaces.dashboard'),
          to: { name: 'WorkspacesDashboard' },
          exact: true
        },
        {
          disabled: !this.hasWorkspacePermission('view_documents_document'),
          icon: 'inbox',
          component: 'NavigationDrawerGroup',
          activator: this.$t('sidebar.documents.inbox'),
          children: [
            {
              text: this.$t('sidebar.documents.invoices'),
              to: {
                name: 'DocumentsInbox',
                params: { documentCategory: 'invoices' }
              }
            },
            {
              text: this.$t('sidebar.documents.orders'),
              to: {
                name: 'DocumentsInbox',
                params: { documentCategory: 'purchase_orders' }
              }
            }
          ]
        },
        {
          disabled: !this.hasWorkspacePermission('view_documents_document'),
          icon: 'send',
          component: 'NavigationDrawerGroup',
          activator: this.$t('sidebar.documents.sent'),
          children: [
            {
              text: this.$t('sidebar.documents.invoices'),
              to: {
                name: 'DocumentsSent',
                params: { documentCategory: 'invoices' }
              }
            },
            {
              text: this.$t('sidebar.documents.orders'),
              to: {
                name: 'DocumentsSent',
                params: { documentCategory: 'purchase_orders' }
              }
            }
          ]
        },
        {
          disabled: !this.hasWorkspacePermission('view_users_documentreport'),
          icon: 'report',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.reports.reports'),
          to: { name: 'DocumentReportsList' }
        },
        {
          disabled: !this.hasWorkspacePermission('view_users_reportschedule'),
          icon: 'schedule',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.reports.schedules'),
          to: { name: 'ReportSchedulesList' }
        },
        {
          disabled: !this.hasWorkspacePermission('view_users_webformsnippet'),
          icon: 'partners',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.workspaces.parties'),
          to: { name: 'WorkspacesParties' }
        },
        {
          disabled: !this.hasWorkspacePermission('view_users_webformsnippet'),
          icon: 'banks',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.workspaces.bankAccounts'),
          to: { name: 'WorkspacesBankAccounts' }
        },
        {
          disabled: !this.hasWorkspacePermission('view_users_webformsnippet'),
          icon: 'terms',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.workspaces.terms'),
          to: { name: 'WorkspacesTerms' }
        },
        {
          disabled: !this.hasWorkspacePermission('view_documents_receivedfile'),
          icon: 'received',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.acquisition.received'),
          to: { name: 'ReceivedList' }
        },
        {
          disabled: !this.hasWorkspacePermission('view_documents_integrationfile'),
          icon: 'integration',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.integration.files'),
          to: { name: 'IntegrationFilesList' }
        },
        {
          icon: 'shieldAccount',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.administration.permissions'),
          to: { name: 'WorkspacesPermissions' }
        }
      ]
    },
    administrationNavigation () {
      return [
        {
          disabled: false,
          icon: 'clients',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.administration.dashboard'),
          to: { name: 'ClientDetails' },
          exact: true
        },
        // {
        //   disabled: !this.hasClientPermission('view_clients_subscription'),
        //   icon: 'creditCard',
        //   component: 'NavigationDrawerItem',
        //   text: this.$t('sidebar.administration.billing'),
        //   to: { name: 'ClientBilling' }
        // },
        {
          icon: 'shieldAccount',
          component: 'NavigationDrawerItem',
          text: this.$t('sidebar.administration.permissions'),
          to: { name: 'ClientPermissions' }
        }
      ]
    }
  }
}
