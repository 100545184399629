export default {
  'numbers-only': {
    bind (el) {
      el.addEventListener('keypress', ($event) => {
        const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
        if (!String.fromCharCode(keyCode).match(/[0-9-.]/)) $event.preventDefault()
      })
    }
  },
  'integers-only': {
    bind (el) {
      el.addEventListener('keypress', ($event) => {
        const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
        if (keyCode < 48 || keyCode > 57) $event.preventDefault()
      })
    }
  }
}
