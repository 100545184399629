import { apiHttpService } from '@/api'
import getNested from 'get-nested'
import { getWorkspaceId } from '@/api/utils/route_params'

const mapResponse = data => {
  return {
    id: data.id,
    name: data.name,
    clientName: getNested(() => data.client.name, ' '),
    clientAcronym: getNested(() => data.client.acronym, ' '),
    logo: getNested(() => data.client.logo, ''),
    clientId: getNested(() => data.client.id, '')
  }
}
const processApiResponse = results => results.map(result => {
  return mapResponse(result)
})

export default {
  async getUserWorkspaces () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/',
        method: 'GET'
      })
      const processedResponse = processApiResponse(response.data.results)
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getActiveWorkspace () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/',
        method: 'GET'
      })
      const activeWorkspace = mapResponse(response.data)
      return Promise.resolve(activeWorkspace)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
