import {
  mdiAccount,
  mdiAccountCircle,
  mdiLogout,
  mdiLogin,
  mdiEmail,
  mdiDelete,
  mdiFlipHorizontal,
  mdiFileDocument,
  mdiMagnify,
  mdiFilePdf,
  mdiDownload,
  mdiRefresh,
  mdiFilterVariant,
  mdiNotificationClearAll,
  mdiLaunch,
  mdiCalendarRange,
  mdiTrendingDown,
  mdiTrendingUp,
  mdiCheck,
  mdiShieldAccount,
  mdiLockOutline,
  mdiLock,
  mdiEye,
  mdiEyeOff,
  mdiInbox,
  mdiSend,
  mdiPlus,
  mdiPackageDown,
  mdiPackageUp,
  mdiLayers,
  mdiViewDashboard,
  mdiDomain,
  mdiBank,
  mdiTextSubject,
  mdiHelpCircleOutline,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiWeb,
  mdiProgressClock,
  mdiChartTimelineVariant,
  mdiProgressUpload,
  mdiShape,
  mdiShieldLockOutline,
  mdiContentCopy,
  mdiCogOutline,
  mdiTableSettings,
  mdiEmailOpen,
  mdiCursorDefaultClickOutline,
  mdiUndo,
  mdiCancel,
  mdiFileChart,
  mdiCalendarClock,
  mdiStop,
  mdiCardAccountMailOutline,
  mdiServerNetwork,
  mdiDotsVertical,
  mdiAlarm,
  mdiClockStart,
  mdiClockEnd,
  mdiCalendarBlankMultiple,
  mdiCityVariantOutline,
  mdiCreditCardOutline,
  mdiCurrencyUsd,
  mdiArrowLeft,
  mdiArrowRight,
  mdiDotsHorizontal,
  mdiHomeOutline
} from '@mdi/js'

export const icons = {
  iconfont: 'mdiSvg',
  values: {
    account: mdiAccount,
    accountCircle: mdiAccountCircle,
    logout: mdiLogout,
    login: mdiLogin,
    email: mdiEmail,
    emailOpen: mdiEmailOpen,
    deleteFile: mdiDelete,
    flipHorizontal: mdiFlipHorizontal,
    document: mdiFileDocument,
    search: mdiMagnify,
    pdf: mdiFilePdf,
    download: mdiDownload,
    contactEmail: mdiCardAccountMailOutline,
    refresh: mdiRefresh,
    filter: mdiFilterVariant,
    clearFilter: mdiNotificationClearAll,
    launch: mdiLaunch,
    dateRange: mdiCalendarRange,
    trendingDown: mdiTrendingDown,
    trendingUp: mdiTrendingUp,
    check: mdiCheck,
    success: mdiCheck,
    shieldAccount: mdiShieldAccount,
    lockOutline: mdiLockOutline,
    lock: mdiLock,
    visibility: mdiEye,
    visibilityOff: mdiEyeOff,
    inbox: mdiInbox,
    send: mdiSend,
    add: mdiPlus,
    received: mdiPackageDown,
    integration: mdiPackageUp,
    workspaceSelection: mdiLayers,
    dashboard: mdiViewDashboard,
    partners: mdiDomain,
    banks: mdiBank,
    terms: mdiTextSubject,
    helpOutline: mdiHelpCircleOutline,
    error: mdiAlertCircleOutline,
    warningOutline: mdiAlertOutline,
    language: mdiWeb,
    loading: mdiProgressClock,
    timeline: mdiChartTimelineVariant,
    integrationSend: mdiProgressUpload,
    category: mdiShape,
    noPermission: mdiShieldLockOutline,
    documentCopy: mdiContentCopy,
    settings: mdiCogOutline,
    tableSettings: mdiTableSettings,
    clicked: mdiCursorDefaultClickOutline,
    undo: mdiUndo,
    block: mdiCancel,
    report: mdiFileChart,
    schedule: mdiCalendarClock,
    inactive: mdiStop,
    uptime: mdiServerNetwork,
    moreVertical: mdiDotsVertical,
    moreHorizontal: mdiDotsHorizontal,
    time: mdiAlarm,
    start: mdiClockStart,
    end: mdiClockEnd,
    calendar: mdiCalendarBlankMultiple,
    clients: mdiCityVariantOutline,
    creditCard: mdiCreditCardOutline,
    subscription: mdiCurrencyUsd,
    back: mdiArrowLeft,
    next: mdiArrowRight,
    home: mdiHomeOutline
  }
}
