export const general = {
  loading: {
    start: 'START LOADING',
    end: 'END LOADING'
  },
  loadingButton: 'SET BUTTON LOADING STATE',
  createDocument: {
    dialog: 'SET CREATE DOCUMENT DIALOG STATE'
  }
}

export const api = {
  error: 'API ERROR',
  dismissError: 'API DISMISS ERROR',
  success: 'API SUCCESS',
  dismissSuccess: 'API DISMISS SUCCESS'
}

export const form = {
  error: 'SET FORM ERROR STATUS',
  messages: 'SET FORM ERROR MESSAGES'
}

export const network = {
  error: 'NETWORK ERROR',
  dismissError: 'NETWORK DISMISS ERROR'
}

export const workspaces = {
  loading: 'SET WORKSPACES LOADING STATE',
  list: 'GET USER WORKSPACES LIST',
  clearList: 'CLEAR USER WORKSPACES LIST',
  activeWorkspace: 'GET ACTIVE WORKSPACE',
  clearActiveWorkspace: 'CLEAR ACTIVE WORKSPACE',
  pipelines: 'WORKSPACE PIPELINES LIST',
  pipelinesLoading: 'WORKSPACE PIPELINES LOADING STATUS',
  snippets: {
    all: 'GET ALL WORKSPACE SNIPPETS',
    clearAll: 'CLEAR ALL WORKSPACE SNIPPETS',
    dialog: {
      create: {
        state: 'SET SNIPPET CREATE DIALOG STATE'
      },
      delete: {
        state: 'SET SNIPPET DELETE DIALOG STATE'
      }
    }
  },
  permissions: {
    set: 'SET WORKSPACE PERMISSIONS',
    clear: 'CLEAR WORKSPACE PERMISSIONS'
  }
}

export const administration = {
  loading: 'SET CLIENTS LOADING STATE',
  activeClient: 'GET ACTIVE CLIENT',
  clearActiveClient: 'CLEAR ACTIVE CLIENT',
  list: 'GET USER CLIENTS LIST',
  clearList: 'CLEAR USER CLIENTS LIST',
  permissions: {
    set: 'SET CLIENT PERMISSIONS',
    clear: 'CLEAR CLIENT PERMISSIONS'
  }
}

export const subscription = {
  set: 'SET SUBSCRIPTION DATA',
  clear: 'CLEAR SUBSCRIPTION DATA',
  loading: 'SET SUBSCRIPTION LOADING STATE'
}

export const documents = {
  source: 'SET DOCUMENT SOURCE'
}

export const onboarding = {
  status: {
    set: 'SET USER ONBOARDING STATUS'
  }
}
