import amex from 'payment-icons/min/flat/amex.svg'
import diners from 'payment-icons/min/flat/diners.svg'
import discover from 'payment-icons/min/flat/discover.svg'
import jcb from 'payment-icons/min/flat/jcb.svg'
import mastercard from 'payment-icons/min/flat/mastercard.svg'
import unionpay from 'payment-icons/min/flat/unionpay.svg'
import visa from 'payment-icons/min/flat/visa.svg'
import unknown from 'payment-icons/min/flat/default.svg'

const state = {
  paymentMethodLogos: {
    amex: amex,
    diners: diners,
    discover: discover,
    jcb: jcb,
    mastercard: mastercard,
    unionpay: unionpay,
    visa: visa,
    unknown: unknown
  }
}

const getters = {
  getPaymentMethodLogo: state => (brand) => state.paymentMethodLogos[brand]
}

export default {
  state,
  getters
}
