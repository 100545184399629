<template>
  <v-snackbar
    v-model="apiError"
    :timeout="15000"
    color="error"
    rounded
    multi-line
  >
    <v-row>
      <v-col
        v-if="getNetworkErrors"
        cols="12"
      >
        <p>{{ $t('network.error') }}</p>
        <p class="pa-2 ma-0 white red--text">
          {{ $t('network.errorText') }}
        </p>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <p>{{ $t('api.error') }}</p>
        <p
          v-show="apiErrorStatus || apiErrorText"
          class="pa-2 ma-0 white red--text"
        >
          {{ apiErrorStatus }} {{ apiErrorText }}
        </p>
      </v-col>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        text
        rounded
        v-bind="attrs"
        @click.native="clearApiError()"
      >
        {{ $t('general.filters.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ApiErrorAlert',
  computed: {
    ...mapGetters([
      'getApiErrors',
      'getNetworkErrors'
    ]),
    apiError: {
      get () {
        return this.getApiErrors.status
      },
      set () {
        this.clearApiError()
      }
    },
    apiErrorStatus () {
      return this.getApiErrors.error ? this.getApiErrors.error.status : ''
    },
    apiErrorText () {
      return this.getApiErrors.error ? this.getApiErrors.error.statusText : ''
    }
  },
  methods: {
    ...mapActions([
      'clearApiError'
    ])
  }
}
</script>
