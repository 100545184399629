<template>
  <v-list-item
    active-class="blue--text text--darken-4"
    :disabled="item.disabled"
    :to="item.to"
    :exact="item.exact"
    :data-cy="item.to.name"
  >
    <v-list-item-icon>
      <v-icon
        :disabled="item.disabled"
      >
        {{ $vuetify.icons.values[item.icon] }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ item.text }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavigationDrawerItem',
  props: {
    item: { type: Object, default: () => { return {} } }
  }
}
</script>
