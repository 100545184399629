import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './mutation-types'
import workspaces from './modules/workspaces'
import administration from './modules/administration'
import activeWorkspacePipelines from './modules/workspaces/pipelines'
import activeWorkspaceSnippets from './modules/workspaces/snippets'
import documents from './modules/documents'
import workspacesPermissions from './modules/workspaces/permissions'
import clientsPermissions from './modules/administration/permissions'
import subscriptions from './modules/subscriptions'
import paymentMethods from './modules/payment_methods'
import onboarding from './modules/onboarding'

Vue.use(Vuex)

const state = {
  loading: 0,
  createDocument: {
    dialog: false
  },
  apiError: {
    error: '',
    status: false
  },
  formError: false,
  formErrorMessages: {},
  networkError: false,
  apiSuccess: false,
  supportedFileTypes: [
    'csv'
  ],
  supportedPartnerTypes: [
    'supplier',
    'buyer',
    'orderedBy',
    'deliverTo',
    'seller',
    'soldTo',
    'paidBy'
  ],
  supportedBankTypes: [
    'supplierBank',
    'buyerBank',
    'orderedByBank',
    'sellerBank',
    'deliverToBank'
  ],
  requiredIdentifiers: [
    'vat',
    'bulstat',
    'sst'
  ],
  optionalIdentifiers: [
    'gln',
    'siren',
    'siret',
    'gst',
    'hst'
  ],
  supportedPaymentMethods: [
    'bankTransfer',
    'bankDeposit',
    'card',
    'cash'
  ]
}

const getters = {
  getLoadingState: state => state.loading > 0,
  getCreateDocumentDialogState: state => state.createDocument.dialog,
  getApiErrors: state => state.apiError,
  getFormError: state => state.formError,
  getFormErrorMessages: state => state.formErrorMessages,
  getNetworkErrors: state => state.networkError,
  getApiSuccess: state => state.apiSuccess,
  getSupportedFileTypes: state => state.supportedFileTypes,
  getSupportedPaymentMethods: state => state.supportedPaymentMethods,
  getRequiredIdentifiers: state => state.requiredIdentifiers,
  getOptionalIdentifiers: state => state.optionalIdentifiers,
  getAllIdentifiers: state => {
    return state.requiredIdentifiers.concat(state.optionalIdentifiers)
  }
}

const actions = {
  startLoading ({ commit }) {
    commit(types.general.loading.start)
  },
  stopLoading ({ commit }) {
    commit(types.general.loading.end)
  },
  setLoadingStateButton ({ commit }, newState) {
    commit(types.general.loadingButton, newState)
  },
  setCreateDocumentDialogState ({ commit }, newState) {
    commit(types.general.createDocument.dialog, newState)
  },
  setApiError ({ commit }, error) {
    commit(types.api.error, error)
  },
  setNetworkError ({ commit }) {
    commit(types.network.error)
  },
  clearApiSuccess ({ commit }) {
    commit(types.api.dismissSuccess)
  },
  clearApiError ({ commit }) {
    commit(types.api.dismissError)
    commit(types.network.dismissError)
  },
  setFormError ({ commit }, value) {
    commit(types.form.error, value)
  },
  setFormErrorMessages ({ commit }, errors) {
    commit(types.form.messages, errors)
  }
}

const mutations = {
  [types.general.loading.start] (state) {
    state.loading++
  },
  [types.general.loading.end] (state) {
    state.loading--
  },
  [types.general.createDocument.dialog] (state, newState) {
    state.createDocument.dialog = newState
  },
  [types.api.error] (state, error) {
    state.apiError.error = error
    state.apiError.status = true
  },
  [types.form.error] (state, value) {
    state.formError = value
  },
  [types.form.messages] (state, errors) {
    state.formErrorMessages = errors
  },
  [types.api.success] (state, value) {
    state.apiSuccess = value
  },
  [types.api.dismissError] (state) {
    state.apiError.error = ''
    state.apiError.status = false
  },
  [types.api.dismissSuccess] (state) {
    state.apiSuccess = false
  },
  [types.network.error] (state) {
    state.networkError = true
  },
  [types.network.dismissError] (state) {
    state.networkError = false
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    workspaces,
    administration,
    activeWorkspacePipelines,
    activeWorkspaceSnippets,
    documents,
    workspacesPermissions,
    clientsPermissions,
    subscriptions,
    paymentMethods,
    onboarding
  }
})
