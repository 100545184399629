const IntegrationFilesList = () => import('@/features/integration/views/List.vue')
const IntegrationFilesDetails = () => import('@/features/integration/views/Details.vue')

export default [
  {
    path: '/workspaces/:workspaceId/integration/',
    name: 'IntegrationFilesList',
    meta: {
      requiresAuth: true,
      title: 'integration.header.title',
      icon: 'integration',
      filtered: true,
      viewContext: 'integration',
      flowContext: 'integration'
    },
    component: IntegrationFilesList
  },
  {
    path: '/workspaces/:workspaceId/integration/:routeDetailId/',
    name: 'IntegrationFilesDetails',
    meta: {
      requiresAuth: true,
      title: 'integration.detail.header.title',
      icon: 'integration',
      filtered: false
    },
    component: IntegrationFilesDetails
  }
]
