const clearApiError = (_to, _from, store) => store.dispatch('clearApiError')

const startLoading = (to, from, store) => {
  if (to.name !== from.name) {
    store.dispatch('startLoading')
  }
}
const stopLoading = (_to, _from, store) => store.dispatch('stopLoading')

const checkAuth = (to, _from, store, auth, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) &&
  !auth.isAuthenticated()) {
    store.dispatch('stopLoading')
    next(auth.login())
  }
}

const checkUserOnboardingStatus = (to, _from, store, _auth, next) => {
  if (!store.state.onboarding.userIsOnboarded && to.name !== 'Onboarding') {
    store.dispatch('stopLoading')
    next({ name: 'Onboarding' })
  }
  if (store.state.onboarding.userIsOnboarded && to.name === 'Onboarding') {
    store.dispatch('stopLoading')
    next({ name: 'Launcher' })
  }
}

export const globalRouteGuards = {
  beforeEach: [
    clearApiError,
    checkAuth,
    checkUserOnboardingStatus,
    startLoading
  ],
  afterEach: [
    stopLoading
  ]
}
