import api from '@/api/modules/administration'
import * as types from '@/store/mutation-types'

const state = {
  loading: false,
  list: [],
  activeClient: {}
}

const getters = {
  activeClient: state => state.activeClient,
  activeClientDataIsReady: state => Boolean(state.activeClient.name)
}

const actions = {
  getUserClients ({ commit }) {
    commit(types.administration.loading, true)
    return api.getUserClients()
      .then((clientsList) => {
        commit(types.administration.list, clientsList)
        commit(types.administration.loading, false)
      })
      .catch((error) => {
        commit(types.api.error, error)
        commit(types.administration.loading, false)
      })
  },
  getActiveClient ({ commit }) {
    return api.getActiveClient()
      .then((activeClient) => {
        commit(types.administration.activeClient, activeClient)
      })
      .catch((error) => {
        commit(types.api.error, error)
      })
  },
  clearUserClients ({ commit }) {
    commit(types.administration.clearList)
  },
  clearActiveClient ({ commit }) {
    commit(types.administration.clearActiveClient)
  }
}

const mutations = {
  [types.administration.loading] (state, value) {
    state.loading = value
  },
  [types.administration.list] (state, clientsList) {
    state.list = clientsList
  },
  [types.administration.clearList] (state) {
    state.list = []
  },
  [types.administration.activeClient] (state, activeClient) {
    state.activeClient = activeClient
  },
  [types.administration.clearActiveClient] (state) {
    state.activeClient = {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
