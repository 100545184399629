import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { icons } from '@/plugins/vuetify_icons'
import { bg, en } from 'vuetify/lib/locale'

Vue.use(Vuetify)

const opts = {
  lang: {
    locales: { bg, en },
    current: 'en'
  },
  theme: {
    themes: {
      light: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#ffb74d',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FF9800'
      }
    }
  },
  icons: icons
}

export default new Vuetify(opts)
