<template>
  <card-layout
    v-if="context"
    :disable-title="!searchable"
    :loading="loading"
  >
    <template v-slot:title>
      <v-text-field
        v-model="search"
        outlined
        hide-details
        clearable
        :label="labels[context].search"
      />
    </template>
    <template v-slot:content>
      <v-list two-line>
        <v-data-iterator
          :items="items"
          :loading="loading"
          :search="search"
          :hide-default-footer="!searchable"
          :disable-pagination="!searchable"
          :options="{
            itemsPerPage: 5
          }"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15 -1]
          }"
        >
          <template v-slot:loading>
            <v-skeleton-loader type="list-item-avatar-two-line" />
          </template>
          <template v-slot:no-data>
            <h1 class="text-h6 pt-5 grey--text text-center">
              {{ labels[context].noData }}
            </h1>
          </template>
          <template v-slot:item="props">
            <v-list-item>
              <v-list-item-avatar
                tile
                :size="logoSize"
              >
                <v-img
                  v-if="props.item.logo"
                  :src="props.item.logo"
                  contain
                  transition="slide-y-reverse-transition"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ props.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ props.item.clientName }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  :to="routes[context](props.item.id)"
                  color="primary"
                  rounded
                  :icon="$vuetify.breakpoint.smAndDown"
                >
                  <v-icon :left="$vuetify.breakpoint.mdAndUp">
                    {{ $vuetify.icons.values.launch }}
                  </v-icon>
                  <span v-show="$vuetify.breakpoint.mdAndUp">{{ $t('general.select') }}</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="showListDivider(props.index)" />
          </template>
        </v-data-iterator>
      </v-list>
    </template>
  </card-layout>
</template>

<script>
import CardLayout from '@/components/layout/CardLayout'
export default {
  name: 'HomeList',
  components: {
    CardLayout
  },
  props: {
    items: { type: Array, default: () => { return [] } },
    context: { type: String, default: '' },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      search: null,
      routes: {
        workspaces: workspaceId => this.getWorkspacesRoute(workspaceId),
        clients: clientId => this.getClientsRoute(clientId)
      }
    }
  },
  computed: {
    searchable () {
      return this.items.length > 5
    },
    labels () {
      return {
        workspaces: {
          search: this.$t('workspaces.selection.search'),
          noData: this.$t('workspaces.selection.emptyStates.noWorkspaces')
        },
        clients: {
          search: this.$t('administration.clients.search'),
          noData: this.$t('administration.clients.noClients')
        }
      }
    },
    logoSize () {
      return this.$vuetify.breakpoint.mdAndUp
        ? '60'
        : '30'
    }
  },
  methods: {
    showListDivider (index) {
      return index + 1 < this.items.length
    },
    getWorkspacesRoute (workspaceId) {
      return { name: 'WorkspacesDashboard', params: { workspaceId: workspaceId } }
    },
    getClientsRoute (clientId) {
      return { name: 'ClientDetails', params: { clientId: clientId } }
    }
  }
}
</script>
