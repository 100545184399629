import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { dateTimeFormats } from './datetime'
import { numberFormats } from './numbers'
import axios from 'axios'
import { auth } from '@/keycloak'
import vuetify from '@/plugins/vuetify'

Vue.use(VueI18n)

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  vuetify.framework.lang.current = lang
  Vue.cookie.set('userPreferredLanguage', lang, 100)
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

const matchLanguage = locale => {
  return supportedLanguages.find(lang => lang === locale)
}

const getKcLocale = () => {
  return auth.tokenParsed()
    ? auth.tokenParsed().locale
    : false
}

export function definePreferredLanguage () {
  const kcAccountLocale = matchLanguage(getKcLocale())
  const cookieLanguage = matchLanguage(Vue.cookie.get('userPreferredLanguage'))

  return cookieLanguage ||
    kcAccountLocale ||
    i18n.fallbackLocale
}

export const supportedLanguages = ['bg', 'en']

export const i18n = new VueI18n({
  fallbackLocale: 'en',
  dateTimeFormats,
  numberFormats
})

export function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  // return import(/* webpackChunkName: "lang-[request]" */ `@/translation/lang/${lang}.js`).then(
  return import(/* webpackChunkName: "lang-[request]" */ '@/translation/lang/' + lang + '/').then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}
