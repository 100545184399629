import axios from 'axios'
import store from '@/store'
import * as Sentry from '@sentry/browser'
import { responseError, requestError } from '@/api/utils/error_handlers'
import { auth } from '@/keycloak'

const apiBaseConfig = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  responseType: 'json',
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
}

// Axios instances
const apiHttpService = axios.create({
  ...apiBaseConfig
})

const apiSilentHttpService = axios.create({
  ...apiBaseConfig
})

const externalHttpService = axios.create({
  ...apiBaseConfig
})

const errorResponseHandler = (error) => {
  store.dispatch('stopLoading')
  if (error.response) {
    responseError(error)
  } else if (error.request) {
    requestError(error)
  } else {
    store.dispatch('setApiError', error)
  }
  Sentry.captureException(error)
  return error
}

// Request interceptors

apiHttpService.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${auth.token()}`
  store.dispatch('startLoading')
  return config
})

apiSilentHttpService.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${auth.token()}`
  return config
})

externalHttpService.interceptors.request.use(async config => {
  store.dispatch('startLoading')
  return config
})

// Response interceptors

apiHttpService.interceptors.response.use(
  response => {
    store.dispatch('stopLoading')
    return response
  },
  errorResponseHandler
)

externalHttpService.interceptors.response.use(
  response => {
    store.dispatch('stopLoading')
    return response
  },
  errorResponseHandler
)

export {
  apiHttpService,
  apiSilentHttpService,
  externalHttpService
}
