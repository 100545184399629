import { apiHttpService, apiSilentHttpService } from '@/api'
import { getWorkspaceId, getClientId, getAppContext } from '@/api/utils/route_params'

const billingDetails = [
  'billing_name',
  'billing_address',
  'billing_postal_code',
  'billing_city',
  'billing_country'
]

const hasBillingDetails = subscription => {
  const detailsArray = []
  billingDetails.map(prop => {
    detailsArray.push(subscription[prop])
  })

  return detailsArray.filter(prop => prop).length === billingDetails.length
}

const isFreePlan = subscription => {
  return subscription.plan.monthly_amount === 0 && subscription.plan.yearly_amount === 0
}

const transformResponse = data => {
  return {
    clientId: data.client_id,
    createdAt: data.created_at,
    documentsUsage: data.documents_usage,
    id: data.id,
    paidUntil: data.paid_until,
    planId: data.plan.id,
    maxDocuments: data.plan.max_documents,
    maxUsers: data.plan.max_users,
    planName: data.plan.name,
    monthlyAmount: data.plan.monthly_amount,
    yearlyAmount: data.plan.yearly_amount,
    planCurrencyCode: data.plan.currency_code,
    billingStart: data.billing_starts_at,
    billingEnd: data.billing_ends_at,
    billingInterval: data.billing_interval,
    paymentMethodId: data.payment_method_id,
    billingName: data.billing_name,
    billingAddress: data.billing_address,
    billingPostalCode: data.billing_postal_code,
    billingCity: data.billing_city,
    billingCountry: data.billing_country,
    billingVat: data.billing_vat,
    status: data.status,
    timezone: data.timezone,
    updatedAt: data.updated_at,
    hasBillingDetails: hasBillingDetails(data),
    isFreePlan: isFreePlan(data)
  }
}

export default {
  async getSubscription () {
    try {
      const subscriptionUrls = {
        workspaces: '/workspaces/' + getWorkspaceId() + '/client-subscription/',
        administration: '/clients/' + getClientId() + '/subscription/'
      }
      const response = await apiSilentHttpService({
        url: subscriptionUrls[getAppContext()],
        method: 'GET'
      })
      return Promise.resolve(transformResponse(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setSubscriptionPaymentMethod (paymentMethodId) {
    try {
      const response = await apiHttpService({
        url: `/clients/${getClientId()}/subscription/`,
        method: 'PATCH',
        data: {
          payment_method_id: paymentMethodId
        }
      })
      return Promise.resolve(transformResponse(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateSubscription (subscription) {
    try {
      const response = await apiHttpService({
        url: `/clients/${getClientId()}/subscription/`,
        method: 'PATCH',
        data: {
          payment_method_id: subscription.paymentMethodId,
          billing_name: subscription.billingName,
          billing_address: subscription.billingAddress,
          billing_postal_code: subscription.billingPostalCode,
          billing_city: subscription.billingCity,
          billing_country: subscription.billingCountry,
          billing_vat: subscription.billingVat
        }
      })
      return Promise.resolve(transformResponse(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
