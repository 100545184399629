import { apiHttpService } from '@/api'
import { getWorkspaceId, getFlowContext } from '@/api/utils/route_params'

const categoriesMap = {
  acquisition: 'acquisition,document',
  integration: 'integration'
}

const request = (url, currentPage) => {
  return apiHttpService({
    url: url,
    method: 'GET',
    params: {
      categories: categoriesMap[getFlowContext()] || '',
      page: currentPage,
      page_size: 100
    }
  })
}

const processApiResponse = (results) => {
  return results.map((result) => {
    return {
      id: result.id,
      category: result.category,
      name: result.name,
      slug: result.slug
    }
  })
}

export default {
  async getActiveWorkspacePipelines () {
    const url = '/workspaces/' + getWorkspaceId() + '/pipelines/'

    try {
      const resultsList = []
      let currentPage = 0
      let totalPages = 0

      do {
        currentPage++
        const response = await request(url, currentPage)
        const processedResponse = processApiResponse(response.data.results)
        totalPages = response.data.total_pages

        processedResponse.map((item) => {
          resultsList.push(item)
        })
      } while (totalPages > currentPage)

      return Promise.resolve(resultsList)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
