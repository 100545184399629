const sortingContextMap = {
  received: {
    name: 'received_file_name',
    createdAt: 'created_at',
    compDate: 'completed_at'
  },
  documents: {
    docNum: 'number',
    docType: 'category',
    docDate: 'date',
    createdAt: 'created_at',
    completedAt: 'completed_at'
  },
  integration: {
    createdAt: 'created_at'
  },
  snippets: {
    createdAt: 'created_at'
  },
  reports: {
    createdAt: 'created_at'
  },
  subscriptionSchedules: {
    createdAt: 'created_at'
  }
}

export function setPaginationParams (queryParams, params, context) {
  if (queryParams.page) {
    params.page = queryParams.page
  }
  if (queryParams.itemsPerPage) {
    params.page_size = queryParams.itemsPerPage
  }
  if (queryParams.sortDesc && queryParams.sortBy) {
    const descending = queryParams.sortDesc[0] ? '-' : ''
    const sortBy = queryParams.sortBy[0] ? queryParams.sortBy[0] : 'createdAt'
    params.order_by = descending + sortingContextMap[context][sortBy]
  }
}
