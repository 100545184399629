<template>
  <v-app-bar
    app
    :elevate-on-scroll="!appIsLoading"
    color="grey lighten-5"
    :clipped-left="sharedProps.clipped"
  >
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      data-cy="navigation-drawer-button"
      @click.stop="sharedProps.drawer = !sharedProps.drawer"
    />
    <img
      src="@/assets/logo-blue-light.svg"
      height="30px"
      width="auto"
      class="pr-3"
      data-cy="app-logo"
    >
    <v-toolbar-title
      v-show="authenticatedRoute"
      data-cy="app-title"
    >
      <span v-show="$vuetify.breakpoint.smAndUp">MGT Connect</span>
    </v-toolbar-title>
    <v-fade-transition>
      <v-divider
        v-show="(activeWorkspaceDataIsReady || activeClientDataIsReady) && $vuetify.breakpoint.mdAndUp"
        vertical
        inset
        class="ml-6 mr-3"
      />
    </v-fade-transition>
    <workspace-selection-menu v-show="$vuetify.breakpoint.mdAndUp" />
    <client-selection-menu v-show="$vuetify.breakpoint.mdAndUp" />
    <v-spacer />
    <v-chip
      v-if="showTestEnvWarning"
      :input-value="true"
      color="error--text"
    >
      <v-icon left>
        {{ $vuetify.icons.values.error }}
      </v-icon>
      {{ $t('general.testMode') }}
    </v-chip>
    <v-spacer v-if="showTestEnvWarning" />
    <create-document-button />
    <v-menu
      origin="center center"
      transition="slide-y-transition"
      bottom
      offset-y
      nudge-left="16"
      data-cy="language-menu"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          text
          data-cy="language-menu-button"
          v-on="on"
        >
          <img
            v-if="localeFlag"
            :src="localeFlag"
          >
        </v-btn>
      </template>
      <v-list data-cy="language-menu-panel">
        <v-list-item
          v-for="language in languages"
          :key="language.code"
          :data-cy="'lang-' + language.code"
          @click="changeLanguage(language.code)"
        >
          <v-list-item-title>
            <img
              :src="language.flag"
              class="pr-2"
            >
            {{ $t(language.name) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-if="authenticatedRoute"
      icon
      data-cy="profile-drawer-button"
      @click.stop="sharedProps.rightDrawer = !sharedProps.rightDrawer"
    >
      <v-icon class="blue--text">
        {{ $vuetify.icons.values['accountCircle'] }}
      </v-icon>
    </v-btn>
    <v-progress-linear
      :active="appIsLoading"
      :indeterminate="true"
      class="my-0 progress-main"
    />
  </v-app-bar>
</template>

<script>
import { loadLanguageAsync } from '@/translation'
import { mapGetters } from 'vuex'
import WorkspaceSelectionMenu from '@/components/menus/WorkspaceSelectionMenu'
import ClientSelectionMenu from '@/components/menus/ClientSelectionMenu'
import CreateDocumentButton from '@/components/buttons/CreateDocument'

export default {
  name: 'MainToolbar',
  components: {
    WorkspaceSelectionMenu,
    ClientSelectionMenu,
    CreateDocumentButton
  },
  props: {
    sharedProps: { type: Object, default: () => { return {} } },
    authenticatedRoute: { type: Boolean, default: false }
  },
  data () {
    return {
      languages: [
        { code: 'en', name: 'general.en', flag: require('@/assets/flags/en.svg') },
        { code: 'bg', name: 'general.bg', flag: require('@/assets/flags/bg.svg') }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspaceDataIsReady',
      'activeClientDataIsReady'
    ]),
    localeFlag () {
      if (this.languages.find(x => x.code === this.$i18n.locale)) {
        return this.languages.find(x => x.code === this.$i18n.locale).flag
      } else {
        return false
      }
    },
    appIsLoading () {
      return this.$store.getters.getLoadingState
    },
    showTestEnvWarning () {
      return process.env.VUE_APP_MODE === 'test' && this.$vuetify.breakpoint.smAndUp
    }
  },
  methods: {
    async changeLanguage (lang) {
      await loadLanguageAsync(lang)
    }
  }
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
