const ReceivedList = () => import('@/features/received/views/List.vue')
const ReceivedDetails = () => import('@/features/received/views/Details.vue')

export default [
  {
    path: '/workspaces/:workspaceId/acquisition/received/',
    name: 'ReceivedList',
    meta: {
      requiresAuth: true,
      title: 'acquisition.received.header.title',
      icon: 'received',
      filtered: true,
      viewContext: 'received',
      flowContext: 'acquisition'
    },
    component: ReceivedList
  },
  {
    path: '/workspaces/:workspaceId/acquisition/received/:routeDetailId/',
    name: 'ReceivedDetails',
    meta: {
      requiresAuth: true,
      title: 'acquisition.received.detail.header.title',
      icon: 'received',
      filtered: false,
      viewContext: 'sent'
    },
    component: ReceivedDetails
  }
]
