// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import store from './store'
import VueCookie from 'vue-cookie'
import VueBus from 'vue-bus'
import ObjectHasValues from './plugins/ObjectHasValues'
import GetNumberPlugin from './plugins/getNumber'
import isNumberPlugin from './plugins/isNumber'
import RoundToPlugin from './plugins/roundTo'
import DateInThePastPlugin from './plugins/dateInThePast'
// import Stripe from './plugins/stripe'
import filters from './filters'
import directives from './directives'
import { auth } from '@/keycloak'
import onboardingApi from '@/api/modules/onboarding'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar'
import {
  i18n,
  loadLanguageAsync,
  definePreferredLanguage
} from './translation'
import router from './router'
import { globalRouteGuards } from '@/router/guards/global'
import App from './App'
import './assets/css/main.css'
import './assets/css/router-animation.css'
import './assets/css/animation-overrides.css'
import './assets/css/table.css'

// Plugins
Vue.use(VueCookie)
Vue.use(VueBus)
Vue.use(ObjectHasValues)
Vue.use(GetNumberPlugin)
Vue.use(isNumberPlugin)
Vue.use(RoundToPlugin)
Vue.use(DateInThePastPlugin)
// Vue.use(Stripe)
Vue.use(VueGtag, {
  config: { id: 'UA-154452289-1' },
  disableScriptLoad: process.env.NODE_ENV !== 'production'
}, router)
Vue.use(Hotjar, {
  id: '1762301',
  isProduction: process.env.NODE_ENV === 'production'
})

// Filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// Directives
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key])
})

// sentry.io integration
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'preprod') {
  Sentry.init({
    dsn: 'https://0b0ca36b99724f81948029071f4418e3@sentry.io/1307920',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'https://preprod.app.mgt-connect.com/', 'https://app.mgt-connect.com/', /^\//]
      })
    ]
  })
}

// Global router guards
router.beforeEach(async (to, from, next) => {
  globalRouteGuards.beforeEach.forEach(action => action(to, from, store, auth, next))
  next()
})
router.afterEach((to, from) => {
  globalRouteGuards.afterEach.forEach(action => action(to, from, store))
})

Vue.config.productionTip = false
Vue.config.performance = true

auth
  .init()
  .then(async authenticated => {
    if (!authenticated) {
      auth.login()
      return
    }

    // set user onboarding status
    store.commit('SET USER ONBOARDING STATUS', await onboardingApi.getUserOnboardingStatus())

    // Init vue app
    const vueInstance = new Vue({
      vuetify,
      router,
      store,
      i18n,
      components: {
        App
      },
      render: h => h(App)
    })

    // Load user language and mout app
    loadLanguageAsync(definePreferredLanguage())
      .then(() => {
        vueInstance.$mount('#app')
      })
  })
