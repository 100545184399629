const DOCUMENT_CATEGORIES = {
  invoice: 'invoice',
  creditNote: 'credit_note',
  debitNote: 'debit_note',
  proformInvoice: 'proform_invoice',
  purchaseOrder: 'purchase_order'
}

export const getEntityCardData = entity => {
  return entity.card || {}
}

export const getTermsAndConditions = terms => {
  return terms ? terms.map(term => term.card) : []
}

export const getIsAttachedPDF = isAttachedPDF => {
  return isAttachedPDF === true
}

export const getIsPushEmail = isPushEmail => {
  return isPushEmail === true
}

export const cleanEmptyKeys = (_, value) => {
  if (Array.isArray(value) && value.length === 0) return undefined
  if (typeof value === 'object' && value && Object.keys(value).length === 0) return undefined
  if (value === false) return false
  if (value === 0) return 0
  return value || undefined
}

export const isNumber = value => {
  const parsedNumber = parseFloat(value)
  return Number.isFinite(parsedNumber)
}
export const getNumber = number => {
  const parsedNumber = parseFloat(number)
  return Number.isFinite(parsedNumber) ? parsedNumber : 0
}

export const getApiDocumentCategory = category => {
  return DOCUMENT_CATEGORIES[category]
}
