import Home from '@/features/home/views/Home'

export default [
  {
    path: '',
    name: 'Launcher',
    meta: {
      requiresAuth: true,
      title: 'workspaces.selection.body.title',
      icon: 'workspaceSelection',
      filtered: false
    },
    component: Home
  }
]
