import * as types from '@/store/mutation-types'

const state = {
  userIsOnboarded: false
}

const mutations = {
  [types.onboarding.status.set] (state, onboardingStatus) {
    state.userIsOnboarded = onboardingStatus
  }
}

export default {
  state,
  mutations
}
