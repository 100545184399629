<template>
  <v-slide-x-transition>
    <v-btn
      v-if="$route.params.workspaceId"
      :disabled="disabled"
      rounded
      large
      color="primary"
      class="mr-3"
      :to="{
        name: 'DocumentsCreate',
        params: { workspaceId: $route.params.workspaceId }
      }"
    >
      <v-icon left>
        {{ $vuetify.icons.values.add }}
      </v-icon>
      {{ label }}
    </v-btn>
  </v-slide-x-transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CreateDocumentButton',
  computed: {
    ...mapGetters([
      'hasWorkspacePermission',
      'subscriptionLimitReached'
    ]),
    label () {
      return this.$vuetify.breakpoint.mdAndUp
        ? this.$t('sidebar.create')
        : this.$t('sidebar.createMobile')
    },
    disabled () {
      return !this.hasWorkspacePermission('add_documents_receivedfile') || this.subscriptionLimitReached
    }
  }
}
</script>
