<template>
  <v-toolbar dense>
    <div v-if="hasSubscription">
      <div class="text-caption">
        {{ subscription.planName }}
      </div>
      <div class="text-caption">
        <span :class="`${getSubscriptionStatusColor}--text`">
          {{ subscription.documentsUsage }}
        </span>
        {{ $t('general.of') }}
        {{ subscription.maxDocuments }}
        <span class="grey--text">
          {{ $t('general.dmo') }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="text-caption"
    >
      {{ $t('subscriptions.noSubscriptionData') }}
    </div>
    <v-spacer />
    <!-- <v-btn
      v-if="hasSubscription"
      icon
      small
      active-class="primary--text"
      :to="{ name: 'ClientBilling', params: { clientId: subscription.clientId }}"
    >
      <v-icon small>
        {{ $vuetify.icons.values.settings }}
      </v-icon>
    </v-btn> -->
    <v-divider
      vertical
      inset
      class="mx-3"
    />
    <v-btn
      icon
      small
      :loading="loading"
      @click="fetchData"
    >
      <v-icon small>
        {{ $vuetify.icons.values.refresh }}
      </v-icon>
    </v-btn>
    <v-progress-linear
      :active="true"
      :value="getDocumentUsagePercentage"
      absolute
      bottom
      :color="getSubscriptionStatusColor"
    />
  </v-toolbar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'PlanDetails',
  data () {
    return {
      loading: false,
      pollSubscription: false
    }
  },
  computed: {
    ...mapState({
      subscription: state => state.subscriptions.subscription,
      hasSubscription: state => state.subscriptions.hasSubscription,
      loadingSubscription: state => state.subscriptions.loadingSubscription
    }),
    ...mapGetters([
      'getDocumentUsagePercentage'
    ]),
    getSubscriptionStatusColor () {
      let color = 'primary'
      if (this.getDocumentUsagePercentage > 80) color = 'warning'
      if (this.getDocumentUsagePercentage > 90) color = 'error'
      return color
    }
  },
  watch: {
    '$route.params.clientId': {
      handler: function () {
        this.clearAndFetchData()
      }
    },
    '$route.params.workspaceId': {
      handler: function () {
        this.clearAndFetchData()
      }
    },
    hasSubscription (value) {
      if (value) {
        this.refreshSubscription()
      } else {
        clearInterval(this.pollSubscription)
      }
    }
  },
  created () {
    this.$bus.on('created:document', this.handleDocumentCreatedEvent)
    this.$bus.on('updated:subscription', this.fetchData)
    this.$bus.on('deleted:payment-method', this.fetchData)
  },
  mounted () {
    this.fetchData()
  },
  beforeDestroy () {
    this.$bus.off('created:document', this.handleDocumentCreatedEvent)
    this.$bus.off('updated:subscription', this.fetchData)
    this.$bus.off('deleted:payment-method', this.fetchData)
    clearInterval(this.pollSubscription)
    this.clearSubscriptionData()
  },
  methods: {
    ...mapActions([
      'getSubscriptionData',
      'clearSubscriptionData'
    ]),
    handleDocumentCreatedEvent () {
      if (this.hasSubscription) {
        this.fetchData()
      }
    },
    async fetchData () {
      this.loading = true
      await this.getSubscriptionData()
      this.loading = false
    },
    clearAndFetchData () {
      this.clearSubscriptionData()
      this.getSubscriptionData()
    },
    refreshSubscription () {
      this.pollSubscription = setInterval(function () {
        this.fetchData()
      }.bind(this), 180000)
    }
  }
}
</script>
