<template>
  <v-toolbar
    class="transparent"
    flat
  >
    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>
    <v-divider
      v-if="showDivider"
      class="mx-4"
      inset
      vertical
    />
    <slot name="chip" />
    <v-spacer />
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp && showOptions"
      icon
      :disabled="!hasWorkspacePermission(permissions.edit)"
      @click="$bus.emit('open:edit-dialog')"
    >
      <v-icon>
        {{ $vuetify.icons.values.edit }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp && showOptions"
      icon
      :disabled="!hasWorkspacePermission(permissions.delete)"
      class="ml-2"
      @click="$bus.emit('open:delete-dialog', detailData)"
    >
      <v-icon>
        {{ $vuetify.icons.values.deleteFile }}
      </v-icon>
    </v-btn>
    <slot name="custom-action" />
    <slot name="menu">
      <details-tools-menu
        v-if="$vuetify.breakpoint.smAndDown && showOptions"
        :icon="$vuetify.icons.values.schedule"
        :title="$t('schedules.schedule')"
        :subtitle="detailData.title"
        :permissions="permissions"
      />
    </slot>
  </v-toolbar>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailsToolsMenu from '@/components/menus/DetailsToolsMenu'

export default {
  name: 'DetailsToolbar',
  components: {
    DetailsToolsMenu
  },
  props: {
    title: { type: String, default: '' },
    detailData: { type: Object, default: () => { return {} } },
    permissions: { type: Object, default: () => { return {} } },
    showOptions: { type: Boolean, default: false },
    showDivider: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission'
    ])
  }
}
</script>
