import { apiHttpService } from '@/api'
import { getClientId } from '@/api/utils/route_params'

const mapResponse = response => {
  return {
    id: response.id,
    name: response.name,
    contactEmail: response.contact_email,
    contactName: response.contact_name,
    contactNumber: response.contact_telephone_number,
    logo: response.logo
  }
}
const processListResponse = results => results.map(result => {
  return mapResponse(result)
})

const requestMapping = {
  name: 'name',
  contactEmail: 'contact_email',
  contactName: 'contact_name',
  contactNumber: 'contact_telephone_number'
}

const getClientFormData = form => {
  const cleanForm = JSON.parse(JSON.stringify(form))
  delete cleanForm.logo
  const formData = new FormData()
  Object.entries(cleanForm).forEach(([key, value]) => {
    formData.append(requestMapping[key], value)
  })
  if (form.logo instanceof File) {
    formData.append('logo', form.logo)
  }
  return formData
}

export default {
  async getUserClients () {
    try {
      const response = await apiHttpService({
        url: '/clients/',
        method: 'GET'
      })
      const processedResponse = processListResponse(response.data.results)
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getActiveClient () {
    try {
      const response = await apiHttpService({
        url: '/clients/' + getClientId() + '/',
        method: 'GET'
      })
      const activeClient = mapResponse(response.data)
      return Promise.resolve(activeClient)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async submitData (form) {
    try {
      const formData = getClientFormData(form)
      const response = await apiHttpService({
        url: '/clients/' + getClientId() + '/',
        method: 'PATCH',
        data: formData
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
