<template>
  <v-menu
    transition="scale-transition"
    max-height="600"
  >
    <template v-slot:activator="{ on }">
      <v-slide-x-transition>
        <v-btn
          v-show="showButton && $vuetify.breakpoint.mdAndUp"
          text
          class="text-capitalize"
          :ripple="false"
          :disabled="disableButton"
          v-on="on"
        >
          {{ activeClient.name }}
          <v-icon right>
            {{ $vuetify.icons.values.expand }}
          </v-icon>
        </v-btn>
      </v-slide-x-transition>
    </template>
    <v-list
      dense
      nav
    >
      <v-subheader>
        {{ $t('administration.clients.body.choose') }}
      </v-subheader>
      <v-list-item
        v-for="client in userClientsList"
        :key="client.id"
        :to="{ name: 'ClientDetails', params: { clientId: client.id } }"
        active-class="primary--text"
      >
        <v-list-item-content>
          {{ client.name }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ClientSelectionMenu',
  computed: {
    ...mapState({
      userClientsList: state => state.administration.list
    }),
    ...mapGetters([
      'getLoadingState',
      'activeClient',
      'activeClientDataIsReady'
    ]),
    showButton () {
      return this.$route.params.clientId &&
      this.activeClientDataIsReady
    },
    disableButton () {
      return this.userClientsList.length <= 1 ||
      this.getLoadingState
    }
  }
}
</script>
