import api from '@/api/modules/administration/permissions'
import * as types from '@/store/mutation-types'

const state = {
  permissions: []
}

const getters = {
  hasClientPermission: (state) => (permission) => {
    return state.permissions.includes(permission)
  }
}

const actions = {
  async getClientPermissions ({ commit }) {
    try {
      const permissionsList = await api.getClientPermissions()
      commit(types.administration.permissions.set, permissionsList)
    } catch (error) {
      commit(types.api.error, error)
    }
  },
  clearClientPermissions ({ commit }) {
    commit(types.administration.permissions.clear)
  }
}

const mutations = {
  [types.administration.permissions.set] (state, permissionsList) {
    state.permissions = permissionsList
  },
  [types.administration.permissions.clear] (state) {
    state.permissions = []
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
