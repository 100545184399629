<template>
  <v-menu
    transition="scale-transition"
    max-height="600"
  >
    <template v-slot:activator="{ on }">
      <v-slide-x-transition>
        <v-btn
          v-show="showButton && $vuetify.breakpoint.mdAndUp"
          text
          class="text-capitalize"
          :ripple="false"
          :disabled="disableButton"
          v-on="on"
        >
          {{ activeWorkspace.name }}
          <v-icon right>
            {{ $vuetify.icons.values.expand }}
          </v-icon>
        </v-btn>
      </v-slide-x-transition>
    </template>
    <v-list
      dense
      nav
    >
      <v-subheader>
        {{ $t('workspaces.selection.body.choose') }}
      </v-subheader>
      <v-list-item
        v-for="workspace in userWorkspacesList"
        :key="workspace.id"
        :to="{ name: 'WorkspacesDashboard', params: { workspaceId: workspace.id } }"
        active-class="primary--text"
      >
        <v-list-item-content>
          {{ workspace.name }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'WorkspaceSelectionMenu',
  computed: {
    ...mapState({
      userWorkspacesList: state => state.workspaces.list
    }),
    ...mapGetters([
      'getLoadingState',
      'activeWorkspace',
      'activeWorkspaceDataIsReady'
    ]),
    showButton () {
      return this.$route.params.workspaceId &&
      this.activeWorkspaceDataIsReady
    },
    disableButton () {
      return this.userWorkspacesList.length <= 1 ||
      this.getLoadingState
    }
  }
}
</script>
