import api from '@/api/modules/workspaces/snippets'
import * as types from '@/store/mutation-types'

const state = {
  all: [],
  categoriesContext: [
    'me',
    'client',
    'shared'
  ]
}

const getters = {
  allWorkspacePartnersSnippets: state => {
    return state.all.filter(snippet => snippet.category === 'partner')
  },
  allWorkspaceBanksSnippets: state => {
    return state.all.filter(snippet => snippet.category === 'bank')
  },
  allWorkspaceTermsSnippets: state => {
    return state.all.filter(snippet => snippet.category === 'terms')
  },
  activeWorkspaceSnippetsCategoriesContext: state => state.categoriesContext
}

const actions = {
  getActiveWorkspaceSnippets ({ commit, rootState }) {
    return api.getActiveWorkspaceSnippets(rootState.queryParams)
      .then(snippets => {
        commit(types.workspaces.snippets.all, snippets)
      })
      .catch((error) => {
        commit(types.api.error, error)
      })
  },
  clearAllWorkspacesSnippets ({ commit }) {
    commit(types.workspaces.snippets.clearAll)
  }
}

const mutations = {
  [types.workspaces.snippets.all] (state, snippets) {
    state.all = snippets
  },
  [types.workspaces.snippets.clearAll] (state) {
    state.all = []
  },
  [types.workspaces.snippets.dialog.create.state] (state, newState) {
    state.createBankAccountsDialog = newState
  },
  [types.workspaces.snippets.dialog.delete.state] (state, newState) {
    state.deleteBankAccountsDialog = newState
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
