const config = {
  url: process.env.VUE_APP_SSO_URL,
  realm: process.env.VUE_APP_SSO_REALM,
  clientId: process.env.VUE_APP_SSO_CLIENT_ID
}

const initOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  pkceMethod: 'S256'
}

export { config, initOptions }
