import { apiHttpService } from '@/api'

export default {
  async getUserOnboardingStatus () {
    try {
      const response = await apiHttpService({
        url: '/profile/',
        method: 'GET'
      })
      const userOnboardedStatus = response.data.onboarded
      return Promise.resolve(userOnboardedStatus)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async onboardUser (client, workspace, timezone) {
    try {
      const response = await apiHttpService({
        url: '/onboard-user/',
        method: 'POST',
        data: {
          client: {
            name: client.name,
            address: client.address,
            postal_code: client.postalCode,
            city: client.city,
            country: client.country,
            contact_name: client.contactName,
            contact_telephone_number: client.contactTelephoneNumber,
            contact_email: client.contactEmail
          },
          workspace: {
            name: workspace.name
          },
          timezone: timezone
        }
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
