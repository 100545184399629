import * as types from '@/store/mutation-types'

const state = {
  supportedDocuments: [
    'invoice',
    'creditNote',
    'debitNote',
    'proformInvoice',
    'purchaseOrder'
  ],
  documentsGroups: [
    'invoices',
    'purchase_orders'
  ],
  documentsGroupsMap: {
    invoice: 'invoices',
    creditNote: 'invoices',
    debitNote: 'invoices',
    proformInvoice: 'invoices',
    purchaseOrder: 'purchaseOrders'
  },
  source: {}
}

const getters = {
  getSupportedDocuments: state => state.supportedDocuments,
  getDocumentsGroup: (state) => (category) => {
    return state.documentsGroupsMap[category]
  },
  source: state => state.source,
  sourceIsEmpty: state => {
    return Object.keys(state.source).length === 0
  }
}

const actions = {
  setSourceDocument ({ commit }, source) {
    commit(types.documents.source, source)
  },
  setNewDocumentDialogState ({ commit }, newState) {
    commit(types.documents.dialog.new.state, newState)
  }
}

const mutations = {
  [types.documents.source] (state, source) {
    state.source = source
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
