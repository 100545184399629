<template>
  <v-navigation-drawer
    v-model="sharedProps.rightDrawer"
    temporary
    clipped
    hide-overlay
    :right="sharedProps.right"
    fixed
    app
    data-cy="profile-drawer"
  >
    <div class="text-center py-5">
      <v-avatar
        v-if="firstName || lastName"
        size="150"
        color="blue lighten-4"
      >
        <span class="blue--text text--darken-4 text-h2">
          {{ firstName[0] }}.
        </span>
        <span class="blue--text text--darken-4 text-h2">
          {{ lastName[0] }}.
        </span>
      </v-avatar>
      <v-avatar
        v-else
        size="150"
        color="blue lighten-4"
      >
        <v-icon
          class="blue--text text--darken-4"
          size="120"
        >
          {{ $vuetify.icons.values.accountCircle }}
        </v-icon>
      </v-avatar>
    </div>
    <div class="text-center">
      <h3 class="text-h6">
        {{ firstName }} {{ lastName }}
      </h3>
      <div>{{ email }}</div>
    </div>
    <v-divider class="my-4" />
    <v-list
      dense
      nav
      data-cy="profile-drawer-links"
    >
      <v-list-item
        exact
        active-class="primary--text text--darken-4"
        data-cy="link-profile"
        @click="manageAccount"
      >
        <v-list-item-action class="grey--text text--darken-2">
          <v-icon>{{ $vuetify.icons.values['account'] }}</v-icon>
        </v-list-item-action>
        <v-list-item-content class="grey--text text--darken-2">
          <v-list-item-title>{{ $t('user.drawer.profile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        exact
        active-class="primary--text text--darken-4"
        data-cy="link-logout"
        @click="logout"
      >
        <v-list-item-action class="grey--text text--darken-2">
          <v-icon>{{ $vuetify.icons.values.logout }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="grey--text text--darken-2">
            {{ $t('user.drawer.logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { auth } from '@/keycloak'

export default {
  name: 'ProfileDrawer',
  props: {
    sharedProps: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      username: '',
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  created () {
    this.getUserProfileData()
  },
  beforeDestroy () {
    this.sharedProps.rightDrawer = false
  },
  methods: {
    manageAccount () {
      auth.accountManagement()
    },
    async getUserProfileData () {
      const profileData = await auth.loadUserProfile()
      this.username = profileData.username
      this.firstName = profileData.firstName
      this.lastName = profileData.lastName
      this.email = profileData.email
    },
    logout () {
      auth.logout()
    }
  }
}
</script>

<style>
  .profile-nav > .v-list .v-list__tile .nagiation-link {
    font-size: 1rem;
    font-weight: 400;
  }
  .profile-nav .v-list__tile--link {
    border-left: 3px solid #ffffff;
  }
  .profile-nav a.v-list__tile--active {
    background-color: rgba(109, 109, 109, 0.185);
  }
  .profile-nav .active-blue > a.v-list__tile--active {
    border-left: 3px solid #2196f3;
  }
  .profile-nav .active-blue > a:hover {
    border-left: 3px solid #2196f3;
  }
</style>
