const twoDecimalOpts = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false
}

export const numberFormats = {
  en: {
    roundToTwo: {
      ...twoDecimalOpts
    }
  },
  bg: {
    roundToTwo: {
      ...twoDecimalOpts
    }
  }
}
