import { isNumber } from '@/mapping/utils'
import { createMoneyIntlFormatter } from '@easymoney/formatter'
import { createMoney } from '@easymoney/money'

export default {
  twoDecimals: value => {
    return isNumber(value)
      ? value.toFixed(2)
      : value
  },
  formatAndLocalize: (value, locale = undefined) => {
    return isNumber(value)
      ? Intl.NumberFormat(locale, {
        useGrouping: false,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value)
      : value
  },
  formatCurrencyWithCode: (value = 0, locale = undefined, currency = 'EUR') => {
    const money = createMoney({ amount: value, currency: currency })
    const formatted = createMoneyIntlFormatter()
      .format(money, locale, { currencyDisplay: 'code' })
    return formatted
  }
}
