<template>
  <v-card
    outlined
    :loading="loading"
  >
    <v-card-title
      v-if="!disableTitle"
      :class="`font-weight-regular ${titleClass}`"
    >
      <slot name="title" />
    </v-card-title>
    <v-card-text :class="`${contentClass} text--primary`">
      <slot name="content" />
    </v-card-text>
    <v-divider v-if="enableActions" />
    <v-card-actions v-if="enableActions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CardLayout',
  props: {
    disableTitle: { type: Boolean, default: false },
    enableActions: { type: Boolean, default: false },
    titleClass: { type: String, default: '' },
    contentClass: { type: String, default: 'pt-0' },
    loading: { type: Boolean, default: false }
  }
}
</script>
