<template>
  <v-app>
    <navigation-drawer
      v-if="renderNavigationDrawer"
      :shared-props="sharedProps"
    />
    <main-toolbar
      v-if="isLanguageReady"
      :shared-props="sharedProps"
      :authenticated-route="authenticatedRoute"
    />
    <v-main
      v-if="isLanguageReady"
      class="grey lighten-5"
    >
      <workspace-child-header v-if="workspaceRoute" />
      <administration-child-header v-if="administrationRoute" />
      <transition name="router-animation">
        <subscription-alert />
      </transition>
      <transition name="router-animation">
        <router-view />
      </transition>
    </v-main>
    <fab />
    <api-error-alert />
    <api-success-message />
    <profile-drawer
      v-if="isLanguageReady && authenticatedRoute"
      :shared-props="sharedProps"
    />
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/navigation/NavigationDrawer'
import ProfileDrawer from '@/components/drawers/ProfileDrawer'
import MainToolbar from '@/components/toolbars/MainToolbar'
import WorkspaceChildHeader from '@/components/headers/WorkspaceChildHeader'
import AdministrationChildHeader from '@/components/headers/AdministrationChildHeader'
import SubscriptionAlert from '@/components/alerts/SubscriptionAlert'
import Fab from '@/components/buttons/Fab'
import ApiErrorAlert from '@/components/service/ApiErrorAlert'
import ApiSuccessMessage from '@/components/service/ApiSuccessMessage'

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    MainToolbar,
    WorkspaceChildHeader,
    AdministrationChildHeader,
    SubscriptionAlert,
    ProfileDrawer,
    ApiErrorAlert,
    ApiSuccessMessage,
    Fab
  },
  data () {
    return {
      sharedProps: {
        drawer: this.$vuetify.breakpoint.lgAndUp,
        fixed: false,
        clipped: true,
        right: true,
        rightDrawer: false,
        title: 'MGT Solutions'
      }
    }
  },
  computed: {
    isLanguageReady () {
      return this.$i18n.locale
    },
    authenticatedRoute () {
      return this.$route.meta.requiresAuth
    },
    workspaceRoute () {
      return this.$route.params.workspaceId
    },
    administrationRoute () {
      return this.$route.params.clientId
    },
    renderNavigationDrawer () {
      return this.isLanguageReady &&
        this.authenticatedRoute &&
        this.$route.name !== 'Onboarding'
    }
  }
}
</script>
