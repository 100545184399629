// Components
const BaseRouterView = () => import('@/features/administration/router_views/BaseRouterView')
const ContextRouterView = () => import('@/features/administration/router_views/ContextRouterView')
const ClientDetails = () => import('@/features/administration/views/Details')
// const ClientBilling = () => import('@/features/administration/views/Billing')
const ClientPermissions = () => import('@/features/administration/views/Permissions')

export default [
  {
    path: '/administration/',
    component: BaseRouterView,
    meta: {
      appContext: 'administration'
    },
    children: [
      {
        path: ':clientId',
        component: ContextRouterView,
        children: [
          {
            path: '',
            name: 'ClientDetails',
            meta: {
              requiresAuth: true,
              title: 'administration.dashboard.header.title',
              icon: 'clients',
              filtered: false,
              viewContext: 'client'
            },
            component: ClientDetails
          },
          // {
          //   path: 'billing',
          //   name: 'ClientBilling',
          //   meta: {
          //     requiresAuth: true,
          //     title: 'administration.billing.header.title',
          //     icon: 'creditCard',
          //     filtered: false,
          //     viewContext: 'billing'
          //   },
          //   component: ClientBilling
          // },
          {
            path: 'permissions',
            name: 'ClientPermissions',
            meta: {
              requiresAuth: true,
              title: 'administration.permissions.header.title',
              icon: 'shieldAccount',
              filtered: false,
              viewContext: 'subscription'
            },
            component: ClientPermissions
          }
        ]
      }
    ]
  }
]
