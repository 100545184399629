<template>
  <v-navigation-drawer
    v-model="sharedProps.drawer"
    :clipped="sharedProps.clipped"
    enable-resize-watcher
    color="grey lighten-5"
    fixed
    floating
    data-cy="navigation-drawer"
    app
  >
    <v-list
      nav
      shaped
      dense
      expand
    >
      <navigation-drawer-item
        :item="{
          icon: 'home',
          text: this.$t('sidebar.home'),
          to: { name: 'Launcher' },
          exact: true
        }"
      />
      <v-slide-x-transition hide-on-leave>
        <navigation-item-iterator
          :display-condition="workspaceContext"
          :title="$t('sidebar.workspaces.title')"
          :items="workspaceNavigation"
        />
      </v-slide-x-transition>
      <v-slide-x-transition hide-on-leave>
        <navigation-item-iterator
          :display-condition="administrationContext"
          :title="$t('sidebar.administration.title')"
          :items="administrationNavigation"
        />
      </v-slide-x-transition>
      <v-subheader>{{ $t('sidebar.help.title') }}</v-subheader>
      <navigation-drawer-help />
      <navigation-drawer-uptime />
    </v-list>
    <template v-slot:append>
      <v-slide-y-reverse-transition>
        <navigation-drawer-plan-details v-if="showPlanDetails" />
      </v-slide-y-reverse-transition>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { drawer } from '@/mixins/drawer'
import NavigationItemIterator from './drawer/ItemIterator'
import NavigationDrawerItem from './drawer/Item'
import NavigationDrawerHelp from './drawer/Help'
import NavigationDrawerUptime from './drawer/Uptime'
import NavigationDrawerPlanDetails from './drawer/PlanDetails'

export default {
  name: 'NavigationDrawer',
  components: {
    NavigationItemIterator,
    NavigationDrawerItem,
    NavigationDrawerHelp,
    NavigationDrawerUptime,
    NavigationDrawerPlanDetails
  },
  mixins: [drawer],
  props: {
    sharedProps: { type: Object, default: () => { return {} } }
  },
  computed: {
    showPlanDetails () {
      return this.$route.params.workspaceId || this.$route.params.clientId
    }
  }
}
</script>
