<template>
  <v-list-item
    href="https://stats.uptimerobot.com/PNMDLt4KA0"
    target="_blank"
    exact
    color="gray darken-4"
  >
    <v-list-item-icon>
      <v-icon>
        {{ $vuetify.icons.values.uptime }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="navigation-link">
        {{ $t("sidebar.help.uptime") }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavigationDrawerUptime'
}
</script>
