const DocumentsList = () => import('@/features/documents/views/List.vue')
const DocumentsDetails = () => import('@/features/documents/views/Details.vue')
const DocumentsCreate = () => import('@/features/documents/views/Create.vue')

export default [
  {
    path: '/workspaces/:workspaceId/documents/inbox/:documentCategory/',
    name: 'DocumentsInbox',
    meta: {
      requiresAuth: true,
      icon: 'inbox',
      title: 'documents.header.title.inbox',
      filtered: true,
      viewContext: 'inbox',
      flowContext: 'integration'
    },
    component: DocumentsList
  },
  {
    path: '/workspaces/:workspaceId/documents/inbox/:documentCategory/:routeDetailId/',
    name: 'DocumentsInboxDetails',
    meta: {
      requiresAuth: true,
      title: 'documents.detail.header.title.inbox',
      icon: 'document',
      filtered: false
    },
    component: DocumentsDetails
  },
  {
    path: '/workspaces/:workspaceId/documents/sent/:documentCategory/',
    name: 'DocumentsSent',
    meta: {
      requiresAuth: true,
      icon: 'send',
      title: 'documents.header.title.sent',
      filtered: true,
      viewContext: 'sent',
      flowContext: 'acquisition'
    },
    component: DocumentsList
  },
  {
    path: '/workspaces/:workspaceId/documents/sent/:documentCategory/:routeDetailId/',
    name: 'DocumentsSentDetails',
    meta: {
      requiresAuth: true,
      title: 'documents.detail.header.title.sent',
      icon: 'document',
      filtered: false
    },
    component: DocumentsDetails
  },
  {
    path: '/workspaces/:workspaceId/documents/create/',
    name: 'DocumentsCreate',
    meta: {
      requiresAuth: true,
      title: 'documents.create.header.title',
      icon: 'add',
      filtered: false,
      flowContext: 'acquisition'
    },
    component: DocumentsCreate
  },
  {
    path: '/workspaces/:workspaceId/documents/flip/:category/',
    name: 'DocumentsFlip',
    meta: {
      requiresAuth: true,
      title: 'documents.flip.header.title',
      icon: 'flipHorizontal',
      filtered: false,
      flowContext: 'acquisition',
      emptyState: 'FlipEmptyStates'
    },
    component: DocumentsCreate
  },
  {
    path: '/workspaces/:workspaceId/documents/copy/:category/',
    name: 'DocumentsCopy',
    meta: {
      requiresAuth: true,
      title: 'documents.copy.header.title',
      icon: 'documentCopy',
      filtered: false,
      flowContext: 'acquisition',
      emptyState: 'CopyEmptyStates'
    },
    component: DocumentsCreate
  }
]
