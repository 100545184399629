<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-list
          two-line
          class="transparent py-0"
        >
          <v-list-item>
            <v-list-item-avatar tile>
              <v-icon
                color="blue darken-4"
                data-cy="header-icon"
              >
                {{ $vuetify.icons.values[routeProps.icon] }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-if="activeWorkspaceDataIsReady"
                data-cy="header-title"
              >
                {{ $t(routeProps.title) }}
              </v-list-item-title>
              <v-list-item-title
                v-else
                data-cy="header-title"
              >
                {{ $t(routeProps.title) }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="activeWorkspaceDataIsReady"
                data-cy="header-subtitle"
              >
                {{ activeWorkspace.clientName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WorkspaceChildHeader',
  computed: {
    ...mapGetters([
      'activeWorkspace',
      'activeWorkspaceDataIsReady'
    ]),
    routeProps () {
      return {
        title: this.$route.meta.title,
        icon: this.$route.meta.icon,
        filtered: this.$route.meta.filtered
      }
    }
  }
}
</script>
