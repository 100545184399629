const WorkspacesNoPermission = () => import('@/features/errors/views/WorkspacesNoPermission.vue')
const ClientsNoPermission = () => import('@/features/errors/views/ClientsNoPermission.vue')
const Unauthorized = () => import('@/features/errors/views/Unauthorized.vue')
const PageNotFound = () => import('@/features/errors/views/404.vue')

export default [
  {
    path: '/workspaces/:workspaceId/no-permission/',
    name: 'WorkspacesNoPermission',
    component: WorkspacesNoPermission
  },
  {
    path: '/administration/:clientId/no-permission/',
    name: 'ClientsNoPermission',
    component: ClientsNoPermission
  },
  {
    path: '/unauthorized/',
    name: 'Unauthorized',
    component: Unauthorized
  },
  {
    path: '/404/',
    name: 'PageNotFound',
    component: PageNotFound
  }
]
