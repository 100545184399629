<template>
  <v-list-item
    :href="guideLocale"
    target="_blank"
    exact
    color="gray darken-4"
  >
    <v-list-item-icon>
      <v-icon>
        {{ $vuetify.icons.values.helpOutline }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="navigation-link">
        {{ $t("sidebar.help.userGuide") }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavigationDrawerHelp',
  data () {
    return {
      supportedGudeLocales: {
        en: 'https://www.mgt-connect.com/guide/',
        bg: 'https://www.mgt-connect.com/bg/guide/'
      }
    }
  },
  computed: {
    guideLocale () {
      const guideLocale = this.supportedGudeLocales[this.$i18n.locale]
      return guideLocale
        ? this.supportedGudeLocales[this.$i18n.locale]
        : ''
    }
  }
}
</script>
