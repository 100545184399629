// Workspace context routes
import documents from '@/features/documents/routes'
import received from '@/features/received/routes'
import schedules from '@/features/schedules/routes'
import reports from '@/features/reports/routes'
import integration from '@/features/integration/routes'

// Components
const BaseRouterView = () => import('@/features/workspaces/router_views/BaseRouterView')
const ContextRouterView = () => import('@/features/workspaces/router_views/ContextRouterView')
const WorkspacesDashboard = () => import('@/features/workspaces/views/Dashboard.vue')
const WorkspacesParties = () => import('@/features/workspaces/views/BusinessCards.vue')
const WorkspacesBankAccounts = () => import('@/features/workspaces/views/BankAccounts.vue')
const WorkspacesTerms = () => import('@/features/workspaces/views/Terms.vue')
const WorkspacesPermissions = () => import('@/features/workspaces/views/Permissions.vue')

export default [
  {
    path: '/workspaces/',
    component: BaseRouterView,
    meta: {
      appContext: 'workspaces'
    },
    children: [
      {
        path: '',
        redirect: '/'
      },
      {
        path: ':workspaceId',
        component: ContextRouterView,
        children: [
          ...documents,
          ...received,
          ...schedules,
          ...reports,
          ...integration,
          {
            path: '',
            name: 'WorkspacesDashboard',
            meta: {
              requiresAuth: true,
              title: 'workspaces.dashboard.header.title',
              icon: 'dashboard',
              filtered: false,
              viewContext: 'dahsboard'
            },
            component: WorkspacesDashboard
          },
          {
            path: 'parties',
            name: 'WorkspacesParties',
            meta: {
              requiresAuth: true,
              title: 'workspaces.businessCards.header.title',
              icon: 'partners',
              filtered: true,
              viewContext: 'partner'
            },
            component: WorkspacesParties
          },
          {
            path: 'bank-accounts',
            name: 'WorkspacesBankAccounts',
            meta: {
              requiresAuth: true,
              title: 'workspaces.bankAccounts.header.title',
              icon: 'banks',
              filtered: true,
              viewContext: 'bank'
            },
            component: WorkspacesBankAccounts
          },
          {
            path: 'terms-and-conditions',
            name: 'WorkspacesTerms',
            meta: {
              requiresAuth: true,
              title: 'workspaces.terms.header.title',
              icon: 'terms',
              filtered: true,
              viewContext: 'terms'
            },
            component: WorkspacesTerms
          },
          {
            path: 'permissions',
            name: 'WorkspacesPermissions',
            meta: {
              requiresAuth: true,
              title: 'administration.permissions.header.title',
              icon: 'shieldAccount',
              filtered: false,
              viewContext: 'permissions'
            },
            component: WorkspacesPermissions
          }
        ]
      }
    ]
  }
]
