import Keycloak from 'keycloak-js'
import { config, initOptions } from '@/keycloak/config'
import { tokenUpdateInterval } from '@/keycloak/utils'

const keycloak = Keycloak({ ...config })

keycloak.onAuthSuccess = () => (tokenUpdateInterval(keycloak))
keycloak.onAuthLogout = () => (clearInterval(tokenUpdateInterval()))

export const auth = {
  init: () => {
    return keycloak.init({ ...initOptions })
  },
  login: () => {
    return keycloak.login()
  },
  logout: () => {
    return keycloak.logout()
  },
  isAuthenticated: () => {
    return keycloak.authenticated
  },
  token: () => {
    return keycloak.token || null
  },
  tokenParsed: () => {
    return keycloak.tokenParsed || null
  },
  loadUserProfile: () => {
    return keycloak.loadUserProfile()
  },
  accountManagement: () => {
    return keycloak.accountManagement()
  }
}
