import api from '@/api/modules/workspaces/permissions'
import * as types from '@/store/mutation-types'

const state = {
  permissions: []
}

const getters = {
  hasWorkspacePermission: (state) => (permission) => {
    return state.permissions.includes(permission)
  }
}

const actions = {
  async getWorkspacePermissions ({ commit }) {
    try {
      const permissionsList = await api.getWorkspacePermissions()
      commit(types.workspaces.permissions.set, permissionsList)
    } catch (error) {
      commit(types.api.error, error)
    }
  },
  clearWorkspacePermissions ({ commit }) {
    commit(types.workspaces.permissions.clear)
  }
}

const mutations = {
  [types.workspaces.permissions.set] (state, permissionsList) {
    state.permissions = permissionsList
  },
  [types.workspaces.permissions.clear] (state) {
    state.permissions = []
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
