export function objectHasValues (o) {
  try {
    return Object
      .keys(o)
      .filter(key => o[key])
      .length > 0
  } catch (_) {
    return false
  }
}
