import { apiHttpService } from '@/api'
import { getWorkspaceId, getViewContext } from '@/api/utils/route_params'
import { arraySerializer } from '@/api/utils/serializer'
import { setPaginationParams } from '@/api/utils/pagination_params'

const requestPageSize = (url, currentPage) => {
  return apiHttpService({
    url: url,
    method: 'GET',
    params: {
      page: currentPage,
      page_size: 100
    }
  })
}

const processApiResponse = (results) => {
  return results.map((result) => {
    return {
      id: result.id,
      category: result.category,
      context: result.context,
      acronym: result.acronym,
      createdAt: result.created_at,
      card: result.json
    }
  })
}

export default {
  async getWorkspaceSnippets (queryParams) {
    const params = {}

    if (queryParams.activeFilters) {
      params.acronym = queryParams.activeFilters.acronym
      params.context = queryParams.activeFilters.context
    }

    setPaginationParams(queryParams, params, 'snippets')

    params.category = getViewContext()

    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/snippets/',
        method: 'GET',
        params: arraySerializer(params)
      })
      const processedResponse = {
        itemCount: response.data.count,
        pageCount: response.data.total_pages,
        items: processApiResponse(response.data.results)
      }
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getActiveWorkspaceSnippets () {
    const url = '/workspaces/' + getWorkspaceId() + '/snippets/'

    try {
      const resultsList = []
      let currentPage = 0
      let totalPages = 0

      do {
        currentPage++
        const response = await requestPageSize(url, currentPage)
        const processedResponse = processApiResponse(response.data.results)
        totalPages = response.data.total_pages

        processedResponse.map((item) => {
          resultsList.push(item)
        })
      } while (totalPages > currentPage)

      return Promise.resolve(resultsList)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async submitSnippet (form, mode = 'create', id = undefined) {
    const method = mode === 'create' ? 'POST' : 'PATCH'
    const snippetId = id || ''

    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/snippets/' + snippetId,
        method: method,
        data: form
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async submitDeleteRequest (id) {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/snippets/' + id + '/',
        method: 'DELETE'
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
