/*
  tokenUpdateInterval
  Custom update token interval.
  - used in cases when you have problems with the native one from the package.
  - in rare conditions multiple logged in users may have issues related to the native updateToken functionality
    with this you can guarantee the time window when refreshToken is happening.
*/

const updateToken = (keycloak, minValidity) => {
  keycloak.updateToken(minValidity)
    .catch(() => {
      keycloak.clearToken()
      keycloak.logout()
    })
}

export const tokenUpdateInterval = keycloak => {
  return setInterval(() => (updateToken(keycloak, 60)), 10000)
}
