<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <details-toolbar
          :title="$t('workspaces.selection.body.title')"
          :show-divider="false"
        />
        <home-list
          :items="userWorkspacesList"
          context="workspaces"
          :loading="loadingWorkspacesList"
        />
      </v-col>
      <v-col cols="12">
        <details-toolbar
          :title="$t('administration.clients.body.title')"
          :show-divider="false"
        />

        <home-list
          :items="userClientsList"
          context="clients"
          :loading="loadingClientsList"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DetailsToolbar from '@/components/toolbars/DetailsToolbar'
import HomeList from '@/features/home/components/HomeList'

export default {
  name: 'Home',
  components: {
    DetailsToolbar,
    HomeList
  },
  computed: {
    ...mapState({
      userWorkspacesList: state => state.workspaces.list,
      loadingWorkspacesList: state => state.workspaces.loading,
      userClientsList: state => state.administration.list,
      loadingClientsList: state => state.administration.loading
    })
  },
  mounted () {
    this.clearUserWorkspaces()
    this.clearUserClients()
    this.getUserWorkspaces()
    this.getUserClients()
  },
  methods: {
    ...mapActions([
      'getUserWorkspaces',
      'clearUserWorkspaces',
      'getUserClients',
      'clearUserClients'
    ])
  }
}
</script>
