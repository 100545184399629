<template>
  <v-scale-transition origin="center">
    <v-btn
      v-show="matchedRoute"
      :key="$route.name"
      color="primary"
      fixed
      bottom
      right
      fab
      :disabled="!hasWorkspacePermission(routeProps.permission)"
      @click.native="openDialog"
    >
      <v-icon>
        {{ routeProps.icon }}
      </v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Fab',
  data () {
    return {
      routes: {
        DocumentsSentDetails: {
          icon: this.$vuetify.icons.values.file,
          permission: 'add_documents_attachment',
          event: 'open:attachments-sheet'
        },
        WorkspacesBankAccounts: {
          icon: this.$vuetify.icons.values.banks,
          permission: 'add_users_webformsnippet',
          event: 'open:create-dialog'
        },
        WorkspacesParties: {
          icon: this.$vuetify.icons.values.partners,
          permission: 'add_users_webformsnippet',
          event: 'open:create-dialog'
        },
        WorkspacesTerms: {
          icon: this.$vuetify.icons.values.terms,
          permission: 'add_users_webformsnippet',
          event: 'open:create-dialog'
        },
        DocumentReportsList: {
          icon: this.$vuetify.icons.values.report,
          permission: 'add_users_documentreport',
          event: 'open:create-dialog'
        },
        ReportSchedulesList: {
          icon: this.$vuetify.icons.values.schedule,
          permission: 'add_users_reportschedule',
          event: 'open:create-dialog'
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission'
    ]),
    matchedRoute () {
      return this.routes[this.$route.name]
    },
    routeProps () {
      return this.matchedRoute || {}
    }
  },
  methods: {
    openDialog () {
      this.$bus.emit(this.routeProps.event)
    }
  }
}
</script>
