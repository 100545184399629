export function arraySerializer (object) {
  const objKeys = Object.keys(object)

  for (var i = 0; i < objKeys.length; ++i) {
    const key = objKeys[i]
    if (object[key] === null || object[key] === undefined || object[key].length === 0) {
      continue
    }

    if (Array.isArray(object[key])) {
      object[key] = object[key].toString()
    }
  }

  return object
}
